<template>
  <div>
    <Card>
      <template #header>
        <div class="baslik w-100">
          <div>Müşteriler</div>
          <div></div>
        </div>
      </template>
      <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
          <input
            placeholder="Ara.."
            v-model="search"
            class="mr-2 form-control"
          />
        </div>
      </div>

      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Müşteriler Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.id="{ item }">
          <div class="circle">{{ item.fullName.charAt(0) }}</div>
        </template>
        <template v-slot:item.fullName="{ item }">
          <div class="d-flex align-items-center justify-content-between">
            <div class="arrowItem">
              <div>
                <p class="customer-title">{{ item.fullName }}</p>
                <p class="customer-job">
                  {{ $customFunctions.getUserTypeText(item.job) }}
                </p>
                <p class="customer-mail">{{ item.phoneMail }}</p>
              </div>
            </div>
            <button style="cursor: pointer" v-b-modal.my-modal>
              <i class="fas fa-ellipsis-h fa-rotate-90"></i>
            </button>
          </div>
        </template>
        <b-modal id="my-modal" title="Müşteri Detay" >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="isim">İsim</label>
                <input type="text" class="form-control" />
              </div>
            </div>
            <div class="col-6">
                <div class="form-group">
            <label for="meslek">Mesleği</label>
            <input type="text" class="form-control" />
          </div>
            </div>
            <div class="col-6">
                
          <div class="form-group">
            <label for="telefonNo">Telefon Numarası</label>
            <input type="text" class="form-control" />
          </div>
            </div>
            <div class="col-6">
                <div class="form-group">
            <label for="mailAdres">Email Adresi</label>
            <input type="text" class="form-control" />
          </div>
            </div>
          </div>
          <template #modal-footer>
            <!-- <b-button >jaık</b-button> -->
            <!-- <b-button variant="success" @click="">Tamam</b-button> -->
          </template>
        </b-modal>
      </List>
      <!-- Create and Actions Popups -->
    </Card>
  </div>
</template>
<style lang="scss" scoped>
.customer-title {
  color: #333333 !important;
  font-size: 14px !important;
  margin-bottom: 2px !important;
}
.customer-job {
  font-size: 12px !important;
  color: #333333b6 !important;
  margin-bottom: 2px !important;
}
.customer-mail {
  font-size: 11px !important;
  color: #33333396 !important;
  margin-bottom: 2px !important;
}
.circle {
  background-color: rgb(64, 64, 235);
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  width: 45px;
  color: white;
  height: 45px;
}
</style>
<script>
export default {
  name: "musteriler",
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "#", value: "id", size: "60px" },
        { text: "İsim", value: "fullName" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      this.items = [
        {
          id: "1",
          fullName: "Refik Selim Altıok",
          job: "Yazılım Geliştirme",
          phoneMail: "(777) 777 77 77 , refik@dveb.net",
        },
        {
          id: "2",
          fullName: "Ahmet",
          job: "Yazılım Geliştirme",
          phoneMail: "(555) 555 55 55 , ahmet@dveb.net",
        },
        {
          id: "3",
          fullName: "Mehmet",
          job: "Yazılım Geliştirme",
          phoneMail: "(444) 444 44 44 , mehmet@dveb.net",
        },
      ];
      this.totalItems = 3;

      this.pagination = {
        current_page: 1,
        total_pages: 1,
        total_items: 3,
      };
      this.loading = false;
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    confirmModal() {
      this.confirmCreate = true;
      setTimeout(() => {
        this.confirmCreate = false;
      }, 200);
    },
    resetInsertUpdateForm(closeInsertUpdate = false) {
      if (closeInsertUpdate) {
        this.closeInsertUpdate();
        this.getList();
      }

      this.resetForm = true;
      setTimeout(() => {
        this.resetForm = false;
      }, 200);
    },
    closeInsertUpdate() {
      setTimeout(() => {
        this.selectedItem = "";
      }, 100);
      this.showInsertUpdate = false;
    },
    openInsertUpdate(item) {
      this.selectedItem = item;
      this.showInsertUpdate = true;
    },
    closeDetail() {
      this.showDetail = false;
    },
    openDetail(item) {
      this.selectedItem = item;
      this.showDetail = true;
    },
    closeImportModal() {
      this.showImportModal = false;
    },
    openImportModal() {
      this.showImportModal = true;
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `user`;
      this.deleteIds = item.id;
      this.$bvModal.show("modal-deleteOperation");
    },
    selectedItemIdsChanged(payload) {
      this.listSelectedItemIds = payload;
    },
    actions(payload) {
      if (
        this.listSelectedItemIds === [] ||
        this.listSelectedItemIds.length == 0
      ) {
        this.$generateNotification(
          this,
          "warning",
          "İşlem yapılacak seçili kayıt bulunamadı!",
          "İşleme devam edilemiyor."
        );
      } else {
        if (payload == "allRemove") {
          this.deleteOperationUrl = `user`;
          this.deleteIds = this.listSelectedItemIds;
          this.$bvModal.show("modal-deleteOperation");
        } else if (payload == "allInActive" || payload == "allActive") {
          this.loading = true;
          this.$dbFunctions
            .update(`user/update-status`, {
              ids: this.listSelectedItemIds,
              isActive: payload == "allActive",
            })
            .then((res) => {
              this.$dbFunctions.setResponse(this, res);
              this.getList();
            })
            .catch((err) => {
              this.$dbFunctions.setResponse(this, err);
            })
            .finally(() => {
              this.loading = false;
            });
        } else if (payload == "allExport") {
          this.exportOperations(false);
        }
      }
    },
    exportOperations(onlyHeaders) {
      this.loading = true;
      let payload = {
        Ids: this.listSelectedItemIds,
        onlyHeaders: onlyHeaders,
      };

      this.$dbFunctions
        .insertOrUpdate(`user/export`, "export", payload)
        .then((res) => {
          // this.$customFunctions.downloadFile(res.data);

          this.$generateNotification(
            this,
            "success",
            `Size özel dosyanız oluşturuldu. <a href="${res.data}" target="_blank" class="text-dark-50 text-hover-dark">Burayı tıklayarak</a> dosyanızı indirebilirsiniz.`,
            "Dosyanız hazır!"
          );
        })
        .catch((err) => {
          this.$dbFunctions.setResponse(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
